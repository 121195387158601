<template>
  <div id="misc">
    <img
      class="misc-mask"
      height="226"
      :src="
        require(`@/assets/images/misc/misc-mask-${
          $vuetify.theme.dark ? 'dark' : 'light'
        }.png`)
      "
    />

    <div class="page-title text-center px-4 d-flex flex-column align-center">
      <img
        v-if="!$vuetify.theme.isDark"
        src="@/assets/Rideryg_light_theme.png"
        height="100"
      />
      <img v-else src="@/assets/Rideryg_dark_theme.png" height="100" />
      <h2
        class="
          text-2xl
          font-weight-semibold
          text--primary
          d-flex
          align-center
          justify-center
        "
      >
        <span class="me-2">Usuario no pertenece al panel</span>
        <v-icon color="warning">
          {{ icons.mdiAlertOutline }}
        </v-icon>
      </h2>
      <p class="text-sm">
        El usuario no es parte de la nómina del panel corporativo de <span class="font-weight-bold">{{name}}</span>
      </p>

      <div class="misc-character d-flex justify-center">
        <v-img
          max-width="700"
          src="@/assets/images/3d-characters/error.png"
        ></v-img>
      </div>

      <v-btn color="primary" class="mb-4" @click="handle404()">
        Regresar
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mdiAlertOutline } from "@mdi/js";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      icons: {
        mdiAlertOutline,
      },
    };
  },
  computed: {
    ...mapState("auth", ["isLogged", "name"]),
  },
  methods: {
    handle404() {
      if (this.isLogged) {
        this.$router.push({ name: "dashboards" });
      } else {
        this.$router.push({ name: "auth-login" });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/preset/preset/misc.scss";
</style>
